<template>
  <div>
    <canvas id="plant-chart" :width="40" :height="9" style="background-color: white"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import plantChartData from "./data/plant-data";
import colorCodes from "@/views/Reports/GapClosure/constants/colorCodes.js";

export default {
  name: "PlantChart",
  data() {
    return {
      plantChartData: plantChartData
    };
  },
  props: ["gapClosureData"],
  mounted() {
    let statusColor = [];
    this.gapClosureData.status.forEach((status, key) => {
      if (status === 'offtrack' && this.gapClosureData.aop[key] <= this.gapClosureData.completed[key]) {
        statusColor.push(colorCodes['ontrack']);
      } else {
        statusColor.push(colorCodes[status]);
      }
    });
    this.plantChartData.data.labels = this.gapClosureData.label;
    // this.plantChartData.data.datasets[0].data = this.gapClosureData.committed;
    this.plantChartData.data.datasets[0].data = this.gapClosureData.aop && this.gapClosureData.aop.map((i, pos) => (i ? i : null));
    this.plantChartData.data.datasets[1].data = this.gapClosureData.completed.map((i, pos) => (i ? i : 0));
    // this.plantChartData.data.datasets[3].data = this.gapClosureData.notSustained && this.gapClosureData.notSustained.map((i, pos) => (i ? i : null));
    // this.plantChartData.data.datasets[4].data = this.gapClosureData.offtrack && this.gapClosureData.offtrack.map((i, pos) => (i ? i : null));
    // this.plantChartData.data.datasets[5].data = this.gapClosureData.notSustained.map((_i, pos) => ((pos === (new Date().getMonth() + 1)) ? Number(this.gapClosureData.completed[pos]) : null));

    // this.plantChartData.data.datasets[6].data = this.gapClosureData.notSustained.map((_i, pos) => ((pos === (new Date().getMonth() + 1)) && this.gapClosureData.aop && this.gapClosureData.aop.length && Number(this.gapClosureData.aop[pos]) && Number(this.gapClosureData.aop[pos]) - Number(this.gapClosureData.completed[pos]) >= 0 ? Number(this.gapClosureData.aop[pos]) - Number(this.gapClosureData.completed[pos]) : null));

    this.plantChartData.data.datasets[1].backgroundColor = statusColor;
    this.plantChartData.data.datasets[1].borderColor = statusColor;


    // this.plantChartData.data.datasets[5].backgroundColor = this.plantChartData.data.datasets[5].borderColor = this.plantChartData.data.datasets[5].data.map((total, pos) => this.gapClosureData.aop && this.gapClosureData.aop[pos] > total ? '#c11a3b' : '#189D99');
    // this.plantChartData.data.datasets[6].borderColor = this.plantChartData.data.datasets[5].data.map((total, pos) => this.gapClosureData.aop && this.gapClosureData.aop[pos] > total ? '#c11a3b' : '#189D99');

    if (this.gapClosureData.label.length > 14) {
      this.plantChartData.data.datasets[0].datalabels.labels.value.font.size = 9;
      // this.plantChartData.data.datasets[1].datalabels.labels.value.font.size = 12;

      this.plantChartData.data.datasets[1].datalabels.labels.value.font.size = 9;
      this.plantChartData.data.datasets[1].barPercentage = 1;

      // this.plantChartData.data.datasets[3].datalabels.labels.value.font.size = 9;
      // this.plantChartData.data.datasets[3].barPercentage = 1;

      // this.plantChartData.data.datasets[5].datalabels.labels.value.font.size = 9;
      // this.plantChartData.data.datasets[5].barPercentage = 1;

      // this.plantChartData.data.datasets[6].datalabels.labels.value.font.size = 9;
      // this.plantChartData.data.datasets[6].barPercentage = 1;
    } else {
      this.plantChartData.data.datasets[0].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 9 : 11;
      // this.plantChartData.data.datasets[1].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 12 : 15;

      this.plantChartData.data.datasets[1].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
      this.plantChartData.data.datasets[1].barPercentage = 0.5;

      // this.plantChartData.data.datasets[3].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
      // this.plantChartData.data.datasets[3].barPercentage = 0.5;

      // this.plantChartData.data.datasets[4].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
      // this.plantChartData.data.datasets[4].barPercentage = 0.5;

      // this.plantChartData.data.datasets[5].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
      // this.plantChartData.data.datasets[5].barPercentage = 0.5;

      // this.plantChartData.data.datasets[6].datalabels.labels.value.font.size = window.innerWidth < 1500 ? 11 : 16;
      // this.plantChartData.data.datasets[6].barPercentage = 0.5;
    }

    this.plantChartData.options.plugins.datalabels.formatter = (val, context) => {
      if (val === 0 || !val) return "";
      return typeof val === "number" ? val : val[0] - val[1];
    };

    //options for stacked bar graph
    // this.plantChartData.options.scales.x = { stacked: true };
    // this.plantChartData.options.scales.y = { stacked: true };

    // this.plantChartData.data.datasets[2].borderRadius = this.gapClosureData.notSustained.map((count, pos) => {
    //   return count || this.plantChartData.data.datasets[4].data[pos] ? { bottomLeft: 100, bottomRight: 100, topLeft: 0, topRight: 0 } : 100;
    // });
    // this.plantChartData.data.datasets[3].borderRadius = this.gapClosureData.notSustained.map((count, pos) => {
    //   return count && this.plantChartData.data.datasets[4].data[pos]
    //     ? { bottomLeft: 0, bottomRight: 0, topLeft: 0, topRight: 0 }
    //     : { bottomLeft: 0, bottomRight: 0, topLeft: 100, topRight: 100 };
    // });
    // this.plantChartData.data.datasets[4].borderRadius = this.gapClosureData.offtrack.map((count, pos) => {
    //   return count ? { bottomLeft: 0, bottomRight: 0, topLeft: 100, topRight: 100 } : 100;
    // });

    const ctx = document.getElementById("plant-chart");
    Chart.register(ChartJsPluginDataLabels);
    let chart = new Chart(ctx, this.plantChartData);
    console.log(this.plantChartData);
    console.log(chart)
  }
};
</script>
<style scoped>
.blank {
  display: none;
}
</style>
