export const myTeamChart = {
  type: "bar",

  data: {
    labels: [""],
    datasets: [
      {
        // label: "Completed Maturity Assessment Practices",
        type: "bar",
        data: [0],
        backgroundColor: ["#fff", "#258CF3", "#E7DD09", "#696969", "#D3D3D3"],

        barPercentage: 0.15
      }
    ]
  },
  options: {
    responsive: true,
    lineTension: 0,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
            padding: 25
          }
        }
      ]
    },
    plugins: {
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 100,
          bottom: 20
        }
      },
      datalabels: {
        display: false,
        anchor: "end", // remove this line to get label in middle of the bar
        align: "end",
        formatter: (val) => {
          console.log(val);
          if (val) return `${val.toLocaleString()}`;
          return ``;
        },
        labels: {
          value: {
            color: "#004ea8"
          }
        }
      }
    }
  }
};

export default myTeamChart;
