<template>
    <div>
        <div class="each-chart" style="width: 50%;margin: 0 25%;">
            <div class="primary-color card-tab-act" style="
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
          ">
            </div>

            <canvas class="marginClass" :id="'spider-chart'" :width="1" :height="1"
                style="background-color: white"></canvas>

        </div>
    </div>
</template>
  
<script>
import Chart from "chart.js/auto";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import Helper from "@/common/helper/index";
export default {
    name: "MachineSpiderChart",
    data() {
        return {
            helper: Helper,
            plantChartData2: {},
            plantChartData1: {},

        };
    },
    props: ["plantGapClosureData"],
    created() {
        this.plantChartData2 = Helper.getPlantDataSpider();
        this.plantChartData1 = Helper.getPlantDataSpider();
    },
    mounted() {

        let spiderData = this.plantGapClosureData

        let spiderChartData = Helper.getPlantDataSpider();
        let idToSet1 = "spider-chart";
        let datasetToGet1 = spiderData;
        spiderChartData.data.datasets[0].label = "Current Maturity Level";
        spiderChartData.data.datasets[1].label = "Target Maturity Level";
        spiderChartData.data.datasets[0].data = datasetToGet1.datasets[0].data;
        spiderChartData.data.datasets[1].data = datasetToGet1.datasets[1].data;
        spiderChartData.data.labels = spiderData.label;
        Chart.register(ChartJsPluginDataLabels);

        let ctx1 = document.getElementById(idToSet1);
        let obj1 = new Chart(ctx1, spiderChartData);
        obj1.draw()
    },
    methods: {
        randomColor() {
            // Generate a random 2 digit hex number, padded with a 0 if necessary
            const part = () =>
                Math.floor(helper.getRandom() * 256)
                    .toString(16)
                    .padStart(2, '0');
            const r = part();
            const g = part();
            const b = part();
            return [
                "rgba(" + r + ", " + b + ", " + g + ", 0.2)",
                "rgb(" + r + ", " + b + ", " + g + ")",
            ];
        },
    },
};
</script>
  
<style scoped>
.marginClass {
    margin: 2rem;
}

.each-chart {
    width: 49.5%;
    border: 1px solid #ccc;
    padding: 1rem;
    margin-bottom: 1rem;
}

.each-chart-for-2 {
    width: 49.5%;
    border: 1px solid #ccc;
}

.each-chart-for-1 {
    width: 98%;
    border: 1px solid #ccc;
}

.d-flex {
    display: flex;
    justify-content: space-around;
}

.title {
    font: 1.33rem "BarlowR", sans-serif;
}

.principle-list {
    font: 1rem "BarlowR", sans-serif;
    flex: 50%;
}

.principle-list-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sample-list {
    display: flex;
    flex-direction: column;
}
</style>