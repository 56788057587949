<template>
  <div>
    <canvas id="my-team" :width="40" :height="8" style="background-color: white;"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import plantChartData from './data/my-team'
export default {
  name: 'MyTeam',
  props: ['poActionData'],
  data() {
    return {
      plantChartData: plantChartData
    }
  },
  mounted() {
    this.plantChartData.data.labels = this.poActionData.graphLabel
    this.plantChartData.data.labels.unshift("")
    this.plantChartData.data.datasets[0].data = this.poActionData.graphData;
    this.plantChartData.data.datasets[0].data.unshift(0)
    const ctx = document.getElementById('my-team'); Chart.register(ChartJsPluginDataLabels);
    let chart = new Chart(ctx, this.plantChartData);
    console.log(chart)
  }
}
</script>