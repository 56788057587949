<template>
    <div class="education-row">
        <div>
            <div id="chart-header" class="primary-color title-text center-aligned">
                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                    <div class="primary-legend legend"></div>
                    Primary
                </div>
                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                    <div class="secondary-legend legend"></div>
                    Secondary
                </div>
                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                    <div class="tertiary-legend legend"></div>
                    Tertiary
                </div>
            </div>
        </div>
        <div class="card card-cont edu-card">
            <div class="card-body">
                <div class="row">
                    <div class="" v-for="region in regionArr" :key="region.regionId"
                        style="padding-top: 10px; width: 25%; display: flex; flex-direction: column; justify-content: flex-start">
                        <div class="row align-center">
                            <div class="main-tab-act tr-name default-font-color">
                                {{ region.name }}
                            </div>
                        </div>

                        <div class="" v-for="plant in region.plantList" :key="plant.plantId"
                            style="padding-top: 10px; width: 100%">
                            <div class="row align-center">
                                <div class="page-heading-tab plant-name primary-color">
                                    {{ plant.plantName }}
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <b-table id="overall-oee" class="overall-oee-table table-plant-wise" responsive
                                    :items="plant.constraintList" :fields="fields" :tbody-tr-class="rowClass">
                                    <template #cell(constraintName)="row">
                                        <div :title="row.item.constraintName" class="cmg-name">
                                            <div>
                                                <div v-if="row.item.typeName === 'Primary'" class="primary-legend legend">
                                                </div>
                                            </div>
                                            <div>
                                                <div v-if="row.item.typeName === 'Secondary'"
                                                    class="secondary-legend legend"></div>
                                            </div>
                                            <div>
                                                <div v-if="row.item.typeName === 'Tertiary'" class="tertiary-legend legend">
                                                </div>
                                            </div>
                                            {{ constraintNameFormatter(row.item.constraintName) }}
                                        </div>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div class="no-data-found" v-if="!region.plantList.length">
                            <div class="mat-info-con" style="justify-content: center">
                                <div class="no-data">
                                    <img alt="no data" src="@/assets/No_Data.svg" />
                                    <span class="ed-date">No data found</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="font: 1.166667rem 'BarlowR', sans-serif; justify-content: center">
                    NOTE: The Plants and Constraint groups are populated based on Minimum OEE1% for the Primary
                    constraint. Current data is for {{ freezedFor }}.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import apiCalls from "@/common/api/apiCalls";
import api from "@/common/constants/api";
import moment from "moment";

export default {
    name: "MachineReplicationHomeScreen",

    components: {

    },
    data() {
        return {
            freezedFor: moment(new Date()).format("MMM-YYYY"),
            regionArr: [],
            fields: [
                {
                    key: "constraintName",
                    label: "CMG",
                    sortable: false
                },
                {
                    key: "totalNumberOfMachine",
                    label: "#MCH",
                    sortable: false
                },
                {
                    key: "oeE1",
                    label: "OEE 1%",
                    sortable: false
                },
                {
                    key: "oeE2",
                    label: "OEE 2%",
                    sortable: false
                }
            ]
        };
    },
    mounted() { },
    created() {
        let day = 7;
        let year = new Date().getFullYear();
        this.freezedFor = new Date().getDate() <= 7 ? moment(new Date(year, new Date().getMonth() - 1, day)).format("MMM-YY") : moment(new Date()).format("MMM-YY");
        this.getOeeData();
    },
    methods: {
        rowClass(item) {
            if (item && item.pillarActiveStatus == "N") return "inactive-row";
        },
        constraintNameFormatter(name) {
            return name.length > 7 ? name.slice(0, 6) + "..." : name;
        },
        getOeeData() {
            let monthYearToPass = new Date().getDate() <= 7 ? new Date(new Date().getFullYear(), new Date().getMonth() - 1, 7) : new Date();
            apiCalls.httpCallDetails(`${api.GETOVERALLOEE}/${monthYearToPass.getFullYear()}/${monthYearToPass.getMonth() + 1}`, "get").then((data) => {
                console.log(data);
                this.regionArr = [];
                this.regionArr = data.data.map((item) => {
                    let obj = {
                        regionId: item.regionId,
                        name: item.regionName,
                        plantList: []
                    };

                    if (item.listOfPlants.length)
                        obj.plantList = item.listOfPlants.map((plantDetail) => {
                            plantDetail.constraintList.sort((a, b) => {
                                if (a.typeName < b.typeName) return -1;
                                else return a.typeName === b.typeName ? 0 : 1;
                            });
                            return {
                                plantId: plantDetail.plantId,
                                plantName: plantDetail.plantName,
                                constraintList: plantDetail.constraintList.map((constraint) => ({
                                    typeName: constraint.typeName,
                                    groupId: constraint.groupId,
                                    constraintName: constraint.constraintName,
                                    totalNumberOfMachine: constraint.totalNumberOfMachine,
                                    plantId: constraint.plantId,
                                    oeE1: constraint.oeE1 ? Math.round(constraint.oeE1 * 100) / 100 : 0,
                                    oeE2: constraint.oeE2 ? Math.round(constraint.oeE2 * 100) / 100 : 0
                                }))
                            };
                        });


                    return obj;
                });
            });

        }
    }
};
</script>

<style scoped>
.align-center {
    text-align: center;
    justify-content: center;
}

.title-text {
    font: 1.16rem "BarlowR", sans-serif;
    padding: 0 0 2rem 0;
}

.graph-div {
    width: 100%;
    display: flex;
}

.no-data-found {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-aligned {
    text-align: center;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.legend {
    margin: 0 0.5rem 0 0;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
}

.primary-legend {
    background-color: brown;
}

.secondary-legend {
    background-color: #5d94dc;
}

.tertiary-legend {
    background-color: purple;
}

.cmg-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* word-break: break-all; */
}
</style>
